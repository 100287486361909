'use client';

import React from 'react';
import { useTranslations } from 'next-intl';
import StatusCode from 'features/StatusCode';
import { ReactComponent as RefreshIcon } from 'public/assets/icons/refresh.svg';
const ErrorPage = () => {
  const t = useTranslations('Error');
  return <StatusCode code={505} title={t('title')} description={t('description')} actionProps={{
    onClick: () => window.location.reload(),
    children: t('action'),
    startIcon: <RefreshIcon fill="#fff" />
  }} data-sentry-element="StatusCode" data-sentry-component="ErrorPage" data-sentry-source-file="error.tsx" />;
};
export default ErrorPage;